<template>
  <div>
    <van-nav-bar
        title="方安物业缴费系统"
        :left-arrow="isarrow"
        @click-left="onClickLeft"

    />

    <van-card v-for='item in list'
        :key="item.FeeItemName"
        :price="item.PayPrice"


        :thumb="img"
    >

      <template #title>
        <van-tag plain type="danger">{{ item.FeeItemName }}</van-tag>
      </template>
      <template #desc>

      </template>
      <template #footer>
        <div>费用日期：{{item.StartTime}}至{{item.EndTime}}</div>
      </template>

    </van-card>
  </div>
</template>

<script>
import {Button, Cell, CellGroup, List, NavBar, ContactList, Tag, Form, Field, Dialog,Card} from 'vant';
import {queryDoorNoList} from "@/api/wxmppay";
import img from '@/assets/gymimg.png'

export default {
  name: "Orderlist",
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]:  CellGroup,
    [List.name]:List,
    [NavBar.name]:NavBar,
    [ContactList.name]:ContactList,
    [Tag.name]:Tag,
    [Form.name]: Form,
    [Field.name]:Field,
    [Dialog.name]: Dialog,
    [Card.name]: Card,


  },
  data(){
    return {
      isarrow: true,
      list:[],
      query:{
        doorno:""
      },
      img:img
    }
  },
  created() {
    this.query.doorno=this.$route.query.doorno
    //根据房间号获取欠费数据
    queryDoorNoList(this.query).then(res=>{
      this.list=res.list;

    })
  },
  methods:{
    onClickLeft(){
      this.$router.back(-1)
    },
  }
}
</script>

<style scoped>

</style>